<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Terrassenüberdachung / Heroal <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Heroal OR Terrassendach
      </h2>
      <div class="grid grid-cols-2">
        <div class="row-span-4 col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Modernes Terrassendachsystem
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Erstklassige Materialien, technnische Perfektion bis ins Detail und
            eine große Vielfalt von Gestaltungsmöglichkeiten zeichnen die
            effiziente Systemtechnik von Heroal aus. Das sind VOrteile, die
            überzeugen. Seit Jahrzehnten ist Heroal mit innovativen Lösungen aus
            Aluminium und Aluminium-Verbundmaterialien für die Grbäudehülle der
            zuverlässige Partner für Architekten, Bauherren und Verarbeiter.
            <br /><br />
            Das Terrassendachsystem Heroal OR beeindruckt dank kubischer Formen
            durch ein modernes Design. Zudem überzeugt das System durch die
            besonders einfache und effiziente Verarbeitung sowie die
            vielfältigen Kombinationsmöglichkeiten mit vollintegrierbarer
            Ganzglas-Schiebetür und Beschattungselementen. Mit den durchdachten
            Konstruktionsdetails lassen sich diese optisch harmonisch in das
            Terrassendachsystem einbinden und auf Wunsch nachrüsten.
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Ihr Ansprechpartner für Heroal OR">
            <p class="text-lg">
              <span class="font-semibold">BE-RO-MA</span> ist als heroal-Partner
              der richtige Ansprechpartner für Sie in Berlin-Brandenburg wenn es
              um Heroal-Terrassendächer geht. Heroal OR Terrassendach kann
              individuell angepasst werden. Lassen Sie sich beraten.<br /><br />
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/heroal/td/heroal-or-4.webp"),
          alt: "heroal terrassendach",
        },
        {
          image: require("@/assets/img/heroal/td/heroal-or-2.webp"),
          alt: "heroal terrassendach seitlich mit sonnenschutz",
        },
        {
          image: require("@/assets/img/heroal/td/heroal-or-3.webp"),
          alt: "heroal terrassendach gruen",
        },
        {
          image: require("@/assets/img/heroal/td/heroal-or-1.webp"),
          alt: "heroal terrassendach seitlich",
        },
        {
          image: require("@/assets/img/heroal/td/heroal-or-5.webp"),
          alt: "heroal terrassendach mit rollos",
        },
        {
          image: require("@/assets/img/heroal/td/heroal-or-6.webp"),
          alt: "heroal terrassendach innen mit stuehlen und tischen",
        },
      ],
    };
  },
};
</script>
